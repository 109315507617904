import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import { routes } from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import "./assets/scss/main.scss"

Vue.config.productionTip = false

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
