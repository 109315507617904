<template>
  <v-app>
    <v-snackbar centered :value="updateExists" :timeout="-1" color="primary">
    È disponibile un aggiornamento
    <v-btn text @click="refreshApp">
      Aggiorna
    </v-btn>
  </v-snackbar>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        /> -->

        <v-img
          alt="Logo"
          contain
          min-width="50"
          src="@/assets/img/trackbook-rounded.png"
          width="50"
        />
        <strong class="ml-2">Trackbook</strong>
      </div>

      <v-spacer></v-spacer>

      <v-btn
      @click="changeDark"
        icon
      >
        <v-icon v-if="dark">mdi-white-balance-sunny</v-icon>
        <v-icon v-else>mdi-moon-waning-crescent</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <!-- <script v-if="isProduction" id="CookieDeclaration" src="https://consent.cookiebot.com/f3280cff-fb82-40ea-bcb8-5c19da077b5a/cd.js" type="text/javascript" async></script> -->
      <router-view></router-view>
      
    </v-main>
    <v-footer app fixed>
      <bottom-navigation style="height: 56px;" />
    </v-footer>
  </v-app>
</template>

<script>
import BottomNavigation from './components/BottomNavigation.vue';
import { mapGetters } from 'vuex'
import update from './mixins/update.js'

export default {
  components: { BottomNavigation },
  name: 'App',
  mounted() {
    this.$store.dispatch("fetchData")
  },
  methods: {
    changeDark() {
      this.$store.commit("setDark", !this.$vuetify.theme.dark)
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      if(this.$vuetify.theme.dark) {
        document.body.classList.add('bg-dark')
      } else {
        document.body.classList.remove('bg-dark')
      }
    }
  },
  watch: {
    dark() {
      this.$vuetify.theme.dark = this.dark
      if(this.dark) {
        document.body.classList.add('bg-dark')
      } else {
        document.body.classList.remove('bg-dark')
      }
    }
  },
  mixins: [update],
  computed: {
    ...mapGetters(['dark']),
    isProduction() {
      return process.env.NODE_ENV === 'production'
    }
  }
};
</script>
