export const routes = [
  {
    path: '/',
    name: 'Home',
    component:  () => import('../views/Home.vue'),
  },
  {
    path: '/detail-tab/:id',
    name: 'DetailTab',
    component: () => import('../views/DetailTab.vue'),
    meta: {
      sitemap: {
        slugs: [
            '1',
            '2',
        ],
      }
    }
  },
  {
    path: '/faq',
    name: 'Faq',
    component: () => import('../views/Faq.vue')
  }
]
