import { v4 } from "uuid";

export default {
    newCategory,
    newTab,
    newEvent,
    checkIsUndefined,
    checkIsUndefinedOrEmpty,
    checkObjectIsUndefinedOrEmpty,
    checkAreEquals,
    sortArrayOfObjects,
    findObject,
    findIndexWithAttr,
    deleteElementArray,
    insertAt,
    download
}

function newEvent(title, description, status, done, total, id = null) {
    return {
        id: checkIsUndefined(id) ? v4() : id,
        title: title,
        description: description,
        status: status,
        done: done,
        total: total
    }
}

function newCategory(title) {
    return {
        id: v4(),
        title: title,
        elements: []
    }
}

function newTab(title, subtitle, status, done, total, typeTotal, budget, id = null) {
    return {
        id: checkIsUndefined(id) ? v4() : id,
        title: title,
        subtitle: subtitle,
        status: status,
        done: done,
        total: total,
        typeTotal: typeTotal,
        budget: budget,
        events: []
    }
}

function checkIsUndefined(obj) {
    return (obj === null || obj === undefined)
}

function checkIsUndefinedOrEmpty(obj) {
    return (checkIsUndefined(obj) || obj === "" || obj === " ")
}

function checkObjectIsUndefinedOrEmpty(obj) {
    return (checkIsUndefined(obj) || Object.keys(obj).length === 0)
}

function checkAreEquals(obj1, obj2){
    return (!checkIsUndefined(obj1) && obj1 === obj2)
}

function sortArrayOfObjects(array, attribute1, desc = true, attribute2 = undefined){
    var toReturn = array
    if(desc === true){
        toReturn.sort((a,b) => {
            var element1 = a[attribute1]
            var element2 = b[attribute1]
            if(checkIsUndefinedOrEmpty(attribute2)){
                return (element2 > element1) ? 1 : ((element1 > element2) ? -1 : 0)
            }
            return (element2[attribute2] > element1[attribute2]) ? 1 : ((element1[attribute2] > element2[attribute2]) ? -1 : 0)
        });
    } else {
        toReturn.sort((a,b) => {
            var element1 = a[attribute1]
            var element2 = b[attribute1]
            if(checkIsUndefinedOrEmpty(attribute2)){
                return (element1 > element2) ? 1 : ((element2 > element1) ? -1 : 0)
            }
            return (element1[attribute2] > element2[attribute2]) ? 1 : ((element2[attribute2] > element1[attribute2]) ? -1 : 0)
        });
    }
    return toReturn
}

function findObject(array, value, key){
    return array.find(el => el[key] === value)
}

function findIndexWithAttr(array, attr, value) {
    for(var i = 0; i < array.length; i += 1) {
        if(array[i][attr] === value) {
            return i;
        }
    }
    return -1;
}

function deleteElementArray(array, index){
    array.splice(index, 1)
}

function insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
}

function download(link){
    window.open(link, "_blank")
}