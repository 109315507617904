<template>
  <v-bottom-navigation v-model="value" color="primary">
    <v-btn class="h-100" :class="isHome ?'v-btn--active' : 'v-btn--inactive'" value="dashboard" @click="goToPage('Home')">
      <span>Dashboard</span>

      <v-icon>mdi-view-dashboard-edit</v-icon>
    </v-btn>

    <v-btn class="h-100" @click="onNew">
      <span>Nuovo</span>

      <v-icon>mdi-plus</v-icon>
    </v-btn>

    <v-btn class="h-100" :class="isFaq ?'v-btn--active' : 'v-btn--inactive'" value="faq" @click="goToPage('Faq')">
      <span>Domande</span>

      <v-icon>mdi-help</v-icon>
    </v-btn>
    <new-bottom-sheet ref="newBottomSheet" />
    <event-bottom-sheet ref="eventBottomSheet" />
  </v-bottom-navigation>
</template>

<script>
import NewBottomSheet from "./NewBottomSheet.vue"
import EventBottomSheet from "./EventBottomSheet.vue"
  export default {
    name: 'BottomNavigation',

    data: () => ({
      value: "recent"
    }),
    methods: {
      onNew() {
        if(this.isDetail) {
          this.$refs.eventBottomSheet.sheet = true
          return
        }
        this.$refs.newBottomSheet.sheet = true
      },
      goToPage(name) {
        this.$router.push({name: name})
      }
    },
    computed: {
      isHome() {
        return this.$route.name === "Home"
      },
      isFaq() {
        return this.$route.name === "Faq"
      },
      isDetail() {
        return this.$route.name === "DetailTab"
      }
    },
    components: {
      NewBottomSheet,
      EventBottomSheet
    }
  }
</script>
