export default {
    REQUIRED_RULES: [
        v => !!v || 'Campo Richiesto'
    ],
    STATUS_ARRAY: [
        "Inizio",
        "Svolgimento",
        "Fine",
        "Annullamento"
    ],
    Inizio: "#FFC107",
    Svolgimento: "#2196f3",
    Fine: "#4CAF50",
    Annullamento: "#dd3535"
}