<template>
    <v-bottom-sheet v-model="sheet" scrollable persistent>
      <v-sheet
        class="py-3"
        :height="isTab ? '100%' : '200px'"
      >
        <v-list>
            <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <!-- <v-subheader>Nuova</v-subheader> -->
            <v-list-item
            >
            <v-list-item-content class="pa-0">
                <v-list-item-title>Nome</v-list-item-title>
                <v-list-item-subtitle>
                    <v-text-field
                    outlined
                    required
                    :rules="constants.REQUIRED_RULES"
                    v-model="title"
                    placeholder="Nome" />
                </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
            <div v-if="isTab">
              <v-list-item
              >
              <v-list-item-content class="pa-0">
                  <v-list-item-title>Sottotitolo</v-list-item-title>
                  <v-list-item-subtitle>
                      <v-text-field
                      outlined
                      v-model="subtitle"
                      placeholder="Sottotitolo" />
                  </v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
              <v-list-item
              >
              <v-list-item-content class="pa-0">
                  <v-list-item-title>Con stato avanzamento?</v-list-item-title>
                  <v-list-item-subtitle>
                      <v-switch
                        v-model="status"
                      ></v-switch>
                  </v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
              <v-list-item
              >
              <v-list-item-content class="pa-0">
                  <v-list-item-title>Con check?</v-list-item-title>
                  <v-list-item-subtitle>
                      <v-switch
                        v-model="done"
                      ></v-switch>
                  </v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
              <v-list-item
              >
              <v-list-item-content class="pa-0">
                  <v-list-item-title>Con totale?</v-list-item-title>
                  <v-list-item-subtitle>
                      <v-switch
                        v-model="total"
                      ></v-switch>
                  </v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="total"
              >
              <v-list-item-content class="pa-0">
                  <v-list-item-title>Tipo totale?</v-list-item-title>
                  <v-list-item-subtitle>
                      <v-radio-group
                        v-model="typeTotal"
                        row
                      >
                        <v-radio
                          label="Somma"
                          value="somma"
                        ></v-radio>
                        <v-radio
                          label="Budget"
                          value="budget"
                        ></v-radio>
                      </v-radio-group>
                  </v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="typeTotal === 'budget'"
              >
              <v-list-item-content class="pa-0">
                  <v-list-item-title>Budget</v-list-item-title>
                  <v-list-item-subtitle>
                      <v-text-field
                      outlined
                      type="number"
                      pattern="[0-9]*"
                      decimal="true"
                      inputmode="decimal"
                      v-model="budget"
                      placeholder="Budget" />
                  </v-list-item-subtitle>
              </v-list-item-content>
              </v-list-item>
            </div>
            </v-form>
        </v-list>
        <v-btn
          text
          color="secondary"
          @click="confirm"
        >
          Conferma
        </v-btn>
        <v-btn
          text
          color="red"
          @click="close()"
        >
          Annulla
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import constants from "../utils/constants"
import utils from "../utils/utils"

export default {
    name: "ElementBottomSheet",
    data() {
        return {
            id: null,
            sheet: false,
            isTab: false,
            valid: false,
            total: false,
            status: false,
            done: false,
            title: "",
            subtitle: "",
            typeTotal: "somma",
            budget: 0,
            constants,
            element: null
        }
    },
    methods: {
        confirm() {
          if(this.$refs.form.validate()) {
            if(this.isTab) {
              if(utils.checkIsUndefined(this.element)) {
                this.$store.commit("newTab", utils.newTab(this.title, this.subtitle, this.status, this.done, this.total, this.typeTotal, this.budget,))
              } else {
                this.$store.commit("editTab", utils.newTab(this.title, this.subtitle, this.status, this.done, this.total, this.typeTotal, this.budget, this.id))
              }
            } else {
                this.$store.commit("newCategory", utils.newCategory(this.title))
            }
            this.close()
          }
        },
        close() {
          this.$refs.form.reset();
          this.sheet = false
          this.element = null
        }
    },
    watch: {
      element: {
        deep: true,
        handler: function() {
          if(!utils.checkIsUndefined(this.element)) {
            if(this.isTab) {
              this.id = this.element.id
              this.title = this.element.title
              this.subtitle = this.element.subtitle
              this.status = this.element.status
              this.done = this.element.done
              this.total = this.element.total
              this.typeTotal = this.element.typeTotal
              this.budget = this.element.budget
            }
          }
        }
      }
    }
}
</script>