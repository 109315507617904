<template>
    <v-bottom-sheet v-model="sheet" scrollable persistent>
      <v-sheet
        class="py-3"
        height="100%"
      >
        <v-list>
            <v-form
            ref="form"
            v-model="valid"
            lazy-validation
          >
            <!-- <v-subheader>Nuova</v-subheader> -->
            <v-list-item
            >
            <v-list-item-content class="pa-0">
                <v-list-item-title>Nome</v-list-item-title>
                <v-list-item-subtitle>
                    <v-text-field
                    outlined
                    required
                    :rules="constants.REQUIRED_RULES"
                    v-model="title"
                    placeholder="Nome" />
                </v-list-item-subtitle>
            </v-list-item-content>
            </v-list-item>
            <div v-if="!utils.checkIsUndefined(element)">
                 <v-list-item v-if="element.status"
                >

                    <v-list-item-content class="pa-0">
                        <v-list-item-title>Stato</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-select
                            outlined
                            required
                            :rules="constants.REQUIRED_RULES"
                            v-model="status"
                            :items="constants.STATUS_ARRAY"
                            placeholder="Stato" />
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                 <v-list-item v-if="element.done"
                >

                    <v-list-item-content class="pa-0">
                        <v-list-item-title>Check</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-switch
                              v-model="done"
                            ></v-switch>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
                 <v-list-item v-if="element.total"
                >

                    <v-list-item-content class="pa-0">
                        <v-list-item-title>Totale</v-list-item-title>
                        <v-list-item-subtitle>
                            <v-text-field
                            outlined
                            required
                            type="number"
                            pattern="[0-9]*"
                            decimal="true"
                            inputmode="decimal"
                            :rules="constants.REQUIRED_RULES"
                            v-model="total"
                            placeholder="Totale" />
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </div>
            <v-list-item>

                <v-list-item-content class="pa-0">
                    <v-list-item-title>Descrizione</v-list-item-title>
                    <v-list-item-subtitle>
                        <v-textarea
                        outlined
                        required
                        v-model="description"
                        placeholder="Descrizione" />
                    </v-list-item-subtitle>
                </v-list-item-content>
            </v-list-item>
            </v-form>
        </v-list>
        <v-btn
          text
          color="secondary"
          @click="confirm"
        >
          Conferma
        </v-btn>
        <v-btn
          text
          color="red"
          @click="close()"
        >
          Annulla
        </v-btn>
      </v-sheet>
    </v-bottom-sheet>
</template>

<script>
import constants from "../utils/constants"
import utils from "../utils/utils"
import { mapGetters } from "vuex"

export default {
    name: "EventBottomSheet",
    // props: {
    //   event: {
    //     required: false,
    //     type: Object,
    //     default: null
    //   }
    // },
    data() {
        return {
            sheet: false,
            valid: false,
            total: 0,
            id: null,
            done: false,
            status: "",
            title: "",
            description: "",
            constants,
            utils,
            event: null
        }
    },
    computed: {
        ...mapGetters(["element"])
    },
    methods: {
        confirm() {
          if(this.$refs.form.validate()) {
            if(utils.checkIsUndefined(this.event)) {
              this.$store.commit("newEvent", utils.newEvent(this.title, this.description, this.status, this.done, this.total))
            } else {
              this.$store.commit("editEvent", utils.newEvent(this.title, this.description, this.status, this.done, this.total, this.id))
            }
            this.close()
          }
        },
        close() {
          this.$refs.form.reset();
          this.sheet = false
          this.event = null
        }
    },
    watch: {
      event: {
        deep: true,
        handler: function() {
          if(!utils.checkIsUndefined(this.event)) {
            this.id = this.event.id
            this.title = this.event.title
            this.description = this.event.description
            this.done = this.event.done
            this.status = this.event.status
            this.total = this.event.total
          }
        }
      }
    }
}
</script>