import Vue from 'vue'
import Vuex from 'vuex'
import utils from '../utils/utils'
// import data from './data'
import { get, set } from 'idb-keyval';
// import utils from '../utils/utils'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    categories: [],
    tabIndex: null,
    element: null,
    dark: false
  },
  getters: {
    categories(state) {
      return state.categories
    },
    tabIndex(state) {
      return state.tabIndex
    },
    element(state) {
      return state.element
    },
    dark(state) {
      return state.dark
    }
  },
  mutations: {
    setCategories(state, categories = null){
      if(utils.checkIsUndefined(categories)){
        set('categories', state.categories);
        return
      }
      state.categories = categories
      set('categories', categories);
    },
    setDark(state, dark) {
      state.dark = dark
      set('dark', dark)
    },
    newCategory(state, category) {
      state.categories.push(category)
      this.commit("setCategories")
    },
    newTab(state, tab) {
      state.categories[state.tabIndex].elements.push(tab)
      this.commit("setCategories")
    },
    editTab(state, tab) {
      var tabIndex = utils.checkIsUndefinedOrEmpty(state.tabIndex) ? 0 : state.tabIndex
      const index = state.categories[tabIndex].elements.findIndex(object => {
        return object.id === tab.id;
      });
      if(utils.checkIsUndefinedOrEmpty(index)) {
        return
      }
      var element = state.categories[tabIndex].elements[index]
      element.title = tab.title
      element.status = tab.status
      element.done = tab.done
      element.subtitle = tab.subtitle
      element.total = tab.total
      element.typeTotal = tab.typeTotal
      element.budget = tab.budget
      this.commit("setCategories")
    },
    newEvent(state, event) {
      var tabIndex = utils.checkIsUndefinedOrEmpty(state.tabIndex) ? 0 : state.tabIndex
      const index = state.categories[tabIndex].elements.findIndex(object => {
        return object.id === state.element.id;
      });
      if(utils.checkIsUndefinedOrEmpty(index)) {
        return
      }
      state.categories[tabIndex].elements[index].events.push(event)
      this.commit("setCategories")
    },
    editEvent(state, event) {
      console.log(event)
      var tabIndex = utils.checkIsUndefinedOrEmpty(state.tabIndex) ? 0 : state.tabIndex
      const index = state.categories[tabIndex].elements.findIndex(object => {
        return object.id === state.element.id;
      });
      console.log(index)
      if(utils.checkIsUndefinedOrEmpty(index)) {
        return
      }
      const indexEvent = state.categories[tabIndex].elements[index].events.findIndex(object => {
        return object.id === event.id;
      });
      console.log(indexEvent)
      if(utils.checkIsUndefinedOrEmpty(indexEvent)) {
        return
      }
      var eventEdit = state.categories[tabIndex].elements[index].events[indexEvent]
      console.log(eventEdit)
      eventEdit.title = event.title
      eventEdit.description = event.description
      eventEdit.status = event.status
      eventEdit.done = event.done
      eventEdit.total = event.total
      this.commit("setCategories")
    },

    deleteEvent(state, event) {
      var tabIndex = utils.checkIsUndefinedOrEmpty(state.tabIndex) ? 0 : state.tabIndex
      const index = state.categories[tabIndex].elements.findIndex(object => {
        return object.id === state.element.id;
      });
      if(utils.checkIsUndefinedOrEmpty(index)) {
        return
      }
      const indexEvent = state.categories[tabIndex].elements[index].events.findIndex(object => {
        return object.id === event.id;
      });
      if(utils.checkIsUndefinedOrEmpty(indexEvent)) {
        return
      }
      state.categories[tabIndex].elements[index].events.splice(indexEvent, 1)
      this.commit("setCategories")
    },
    setTab(state, element) {
      state.element = element
      this.commit("setCategories")
    },
    setTabIndex(state, index) {
      state.tabIndex = index
      set('tabIndex', index);
    },
    deleteTabIndex(state) {
      state.categories.splice(state.tabIndex, 1)
      this.commit("setCategories")
    },
    deleteTab(state, element) {
      const index = state.categories[state.tabIndex].elements.findIndex(object => {
        return object.id === element.id;
      });
      if(utils.checkIsUndefinedOrEmpty(index)) {
        return
      }
      state.categories[state.tabIndex].elements.splice(index, 1)
      this.commit("setCategories")
    },
  },
  actions: {
    async fetchData({ commit }) {
      var categories = []
      await get("categories")
        .then(resp => {
          if(!utils.checkIsUndefined(resp)) {
            categories = resp
          }
        })
      commit('setCategories', categories);
      var tabIndex = 0
      await get("tabIndex")
        .then(resp => {
          if(!utils.checkIsUndefined(resp)) {
            tabIndex = resp
          }
        })
      commit('setTabIndex', tabIndex);
      var dark = false
      await get("dark")
        .then(resp => {
          if(!utils.checkIsUndefined(resp)) {
            dark = resp
          }
        })
      commit('setDark', dark);
    },
  },
  modules: {
  }
})
