<template>
    <v-bottom-sheet v-model="sheet" persistent>
      <v-sheet

        height="200px"
      >
        <v-list>
            <v-subheader>Nuova</v-subheader>
            <v-list-item
            @click="onNew(false)"
            >
                <v-list-item-title>Categoria</v-list-item-title>
            </v-list-item>
            <v-list-item
            :disabled="categories.length === 0 || $route.name != 'Home'"
            @click="onNew(true)"
            >
                <v-list-item-title>Tab</v-list-item-title>
            </v-list-item>
        </v-list>
        <v-btn
          text
          color="red"
          @click="sheet = !sheet"
        >
          Annulla
        </v-btn>
      </v-sheet>
      <element-bottom-sheet ref="elementBottomSheet" />
    </v-bottom-sheet>
</template>

<script>
import ElementBottomSheet from "./ElementBottomSheet.vue"
import { mapGetters } from 'vuex'
export default {
    name: "NewBottomSheet",
    data() {
        return {
            sheet: false
        }
    },
    methods: {
      onNew(isTab) {
        this.$refs.elementBottomSheet.sheet = true
        this.$refs.elementBottomSheet.isTab = isTab
        this.sheet = false
      }
    },
    computed: {
      ...mapGetters(["categories"])
    },
    components: {
        ElementBottomSheet
    }
}
</script>